<template>
  <div class="w-full">
    <div class="text-body-l mb-14 mt-2 text-center">{{ $t('login.otp.headline') }}</div>
    <i18n-t keypath="login.otp.description" tag="div" scope="global">
      <template #email>
        <strong>{{ store.email }}</strong>
      </template>
    </i18n-t>

    <form class="mt-6" :action="userSessionUrl" method="POST" data-testid="otp-confirmation-form">
      <input type="hidden" name="authenticity_token" :value="authenticityToken" />
      <input type="hidden" name="email" :value="store.email" />
      <TextInput name="otp" :label="$t('login.otp.code_label')" data-testid="otp-code" />
      <button class="btn-primary-black-l w-full" type="submit" :disabled="!meta.valid" data-testid="otp-confirmation-button">
        {{ $t('login.otp.cta') }}
      </button>
    </form>

    <div class="mt-4">
      {{ $t('login.otp.no_otp_question') }}
      <button class="btn-text-black-m" @click="requestNewCode">{{ $t('login.otp.resend_cta') }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { TextInput } from '@ramp106/omrjs-vee-validate'
import { useUserLoginStore } from '@/stores/login'
import authenticityToken from '@/helpers/authenticityToken'
import requestOtpCode from './requestOtpCode'
import { useNotificationStore } from '@/stores/notification'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  otpUrl: string
  userSessionUrl: string
}>()

const { t } = useI18n()
const { showError, showSuccess } = useNotificationStore()
const store = useUserLoginStore()
const router = useRouter()

const schema = object().shape({
  otp: string().required(),
})

const { meta } = useForm({
  validationSchema: toTypedSchema(schema),
})

onMounted(() => {
  if (store.email) return

  router.replace({ name: 'login' })
})

async function requestNewCode() {
  requestOtpCode(props.otpUrl, store.email)
    .then((response) => response.json())
    .then((json) => {
      if (!json.success) {
        showError(json.error)
        return
      }

      showSuccess(t('login.otp.code_resent_message'))
    })
    .catch(() => showError(t('errors.generic')))
}
</script>
