<template>
  <button class="btn-text-purple-m" @click="modal.open()">
    <MdiSvg :path="mdiPencilOutline" size="20" />
    {{ paymentInfoAvailable ? $t('payment_info.edit') : $t('payment_info.add') }}
  </button>

  <ModalDialog ref="modal" class="light">
    <template #header>
      <div class="bg-black text-white">
        <div class="flex justify-between px-4 py-4">
          <div class="flex flex-col gap-4">
            <span class="text-label-m-bold">Ticket #{{ number }}</span>
            <h4 class="text-headline-m-uppercase">{{ ticket.ticketCategory.event.shortName }}</h4>
          </div>
          <MdiSvg class="cursor-pointer" :path="mdiClose" size="24" @click.stop.prevent="modal.close()" />
        </div>
      </div>
    </template>

    <div class="mx-4 max-w-[640px] pb-20 laptop:pb-0">
      <div class="text-headline-s mt-6">{{ $t('payment_info.form.headline') }}</div>
      <p class="mt-4">{{ $t('payment_info.form.disclaimer') }}</p>

      <PaymentInfoForm class="mt-6" :user-properties="userProperties" :ticket="ticket" @close-form="modal.close()" />
    </div>
  </ModalDialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { mdiClose, mdiPencilOutline } from '@mdi/js'
import { MdiSvg, ModalDialog } from '@ramp106/omrjs-core-ui'
import { DashboardTicket, UserPropertiesPreferences } from '@/gql/types'
import PaymentInfoForm from './PaymentInfoForm.vue'

const modal = ref<InstanceType<typeof ModalDialog>>()

const props = defineProps<{
  ticket: DashboardTicket
  number: number
  userProperties: UserPropertiesPreferences
}>()

const paymentInfoAvailable = computed(() => !!props.ticket.paymentInfo)
</script>
