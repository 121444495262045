<template>
  <div>
    <div class="flex gap-4">
      <RadioButton :value="false" name="business" @click="($event.target as HTMLElement).blur()">
        {{ $t('payment_info.fields.business.private') }}
      </RadioButton>
      <RadioButton :value="true" name="business" @click="($event.target as HTMLElement).blur()">
        {{ $t('payment_info.fields.business.company') }}
      </RadioButton>
    </div>
    <div class="mt-6 tablet:flex tablet:justify-center tablet:gap-4">
      <SelectForOptions
        class="tablet:w-[250px]"
        name="salutation"
        :label="`${$t('payment_info.fields.salutation')} *`"
        :options="salutationOptions"
      />
      <TextInput name="firstName" :label="`${$t('payment_info.fields.firstName')} *`" />
      <TextInput name="lastName" :label="`${$t('payment_info.fields.lastName')} *`" />
    </div>
    <TextInput name="email" :label="`${$t('payment_info.fields.email')} *`" />
    <TextInput v-if="values.business" name="company" :label="`${$t('payment_info.fields.company')} *`" />
    <TextInput name="address" :label="`${$t('payment_info.fields.address')} *`" />
    <TextInput name="additionalAddress" :label="$t('payment_info.fields.additionalAddress')" />
    <div class="tablet:flex tablet:justify-center tablet:gap-4">
      <TextInput name="zip" :label="`${$t('payment_info.fields.zip')} *`" />
      <TextInput name="city" :label="`${$t('payment_info.fields.city')} *`" />
      <SelectForOptions name="country" :label="`${$t('payment_info.fields.country')} *`" :options="countryOptions" />
    </div>
    <TextInput v-if="values.business" name="vatNumber" :label="$t('payment_info.fields.vatNumber')" />
    <TextInput v-if="values.business" name="customerReference" :label="$t('payment_info.fields.customerReference')" />

    <div class="flex justify-end gap-4">
      <button class="btn-secondary-purple-m mt-6" type="button" @click="emit('closeForm')">{{ $t('ticket.form.cancel') }}</button>

      <button class="btn-primary-purple-m mt-6 gap-2" :disabled="!meta.valid" type="button" @click="saveChanges">
        {{ $t('ticket.form.save') }}
        <CircleSpinner v-if="loading" size="small" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { DashboardTicket, UserPropertiesPreferences } from '@/gql/types'
import { SelectForOptions, TextInput, RadioButton } from '@ramp106/omrjs-vee-validate'
import { CircleSpinner } from '@ramp106/omrjs-core-ui'
import { paymentInfoSchema } from './paymentInfoSchema'
import { SetPaymentInfoMutationVariables, useSetPaymentInfoMutation } from '@/gql/requests.generated'
import { SalutationTypeEnum } from '@/gql/schema.generated'
import { useNotificationStore } from '@/stores/notification'

const props = defineProps<{
  userProperties: UserPropertiesPreferences
  ticket: DashboardTicket
}>()

const emit = defineEmits(['closeForm'])

const { t } = useI18n()
const { mutate, loading, onDone, onError } = useSetPaymentInfoMutation()
const { showError, showSuccess } = useNotificationStore()
const { values, meta } = useForm({
  validationSchema: toTypedSchema(paymentInfoSchema),
  initialValues: {
    business: props.ticket.paymentInfo?.business ?? false,
    salutation: (props.ticket.paymentInfo?.salutation as SalutationTypeEnum) || undefined,
    firstName: props.ticket.paymentInfo?.firstName ?? undefined,
    lastName: props.ticket.paymentInfo?.lastName ?? undefined,
    email: props.ticket.paymentInfo?.email ?? undefined,
    company: props.ticket.paymentInfo?.company ?? undefined,
    address: props.ticket.paymentInfo?.address ?? undefined,
    additionalAddress: props.ticket.paymentInfo?.additionalAddress ?? undefined,
    zip: props.ticket.paymentInfo?.zip ?? undefined,
    city: props.ticket.paymentInfo?.city ?? undefined,
    country: props.ticket.paymentInfo?.country ?? undefined,
    vatNumber: props.ticket.paymentInfo?.vatNumber ?? undefined,
    customerReference: props.ticket.paymentInfo?.customerReference ?? undefined,
  },
})

onError(() => {
  showError(t('errors.generic'))
})

onDone((result) => {
  if (result?.data?.setPaymentInfo?.errors?.length) {
    showError(result.data.setPaymentInfo.errors[0])
  } else {
    showSuccess(t('ticket.form.saved'))
    emit('closeForm')
  }
})

function saveChanges() {
  if (loading.value) return

  // TODO: How to do this better?
  const attributes = values as SetPaymentInfoMutationVariables['attributes']

  mutate({ ticketId: props.ticket.id, attributes })
}

const salutationOptions = computed(() => props.userProperties.salutations.map((item) => ({ text: item.name, value: item.enum as string })))

const countryOptions = computed(() =>
  props.userProperties.countries.map((item) => ({ text: item.translation, value: item.code })).sort((a, b) => a.text.localeCompare(b.text)),
)
</script>
