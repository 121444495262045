<template>
  <div class="w-full tablet:w-1/2">
    <h2 class="text-headline-s">{{ $t('my_account.company.headlines.position') }}</h2>
    <SelectForOptions class="mt-6" name="jobLevel" :label="$t('my_account.company.job_level')" :options="jobLevelOptions" />
    <SelectForOptions name="companyDepartment" :label="$t('my_account.company.department')" :options="companyDepartmentOptions" />

    <h2 class="text-headline-s mt-20">{{ $t('my_account.company.headlines.company_information') }}</h2>
    <TextInput class="mt-6" name="companyName" :label="$t('my_account.company.name')" />
    <TextInput name="companyEmail" :label="$t('my_account.company.email')" />
    <TextInput name="companyHomepage" :label="$t('my_account.company.homepage')" />
    <TextInput name="companyPhone" :label="$t('my_account.company.phone')" />
    <SelectForOptions name="companyIndustryId" :label="$t('my_account.company.industry')" :options="companyIndustryOptions" />
    <SelectForOptions
      v-if="companyFieldOptions.length"
      name="companyFieldId"
      :label="$t('my_account.company.field')"
      :options="companyFieldOptions"
    />
    <SelectForOptions name="companySize" :label="$t('my_account.company.size')" :options="companySizeOptions" />

    <h2 class="text-headline-s mt-20">{{ $t('my_account.company.headlines.company_address') }}</h2>
    <TextInput class="mt-6" name="companyStreet" :label="$t('my_account.company.street')" />
    <TextInput class="mt-6" name="companyNumber" :label="$t('my_account.company.number')" />
    <TextInput class="mt-6" name="companyAdditionalAddress" :label="$t('my_account.company.company_additional_address')" />
    <TextInput class="mt-6" name="companyZip" :label="$t('my_account.company.company_zip')" />
    <TextInput class="mt-6" name="companyCity" :label="$t('my_account.company.company_city')" />
    <SelectForOptions name="companyCountry" :label="$t('my_account.company.company_country')" :options="countryOptions" />
  </div>

  <button class="btn-primary-purple-m mt-20 gap-2" :disabled="!meta.valid" type="button" @click="saveChanges">
    {{ $t('my_account.company.save') }}
    <CircleSpinner v-if="loading" size="small" />
  </button>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { toTypedSchema } from '@vee-validate/yup'
import { SelectForOptions, TextInput } from '@ramp106/omrjs-vee-validate'
import { CircleSpinner } from '@ramp106/omrjs-core-ui'
import { MeType, UserPropertiesPreferences } from '@/gql/types'
import { useUpdateUserMutation } from '@/gql/requests.generated'
import { useNotificationStore } from '@/stores/notification'
import { computed } from 'vue'
import { companyDetailsSchema } from './companyDetailsSchema'

const { t } = useI18n()

const props = defineProps<{
  me: MeType
  userPropertiesPreferences: UserPropertiesPreferences
}>()
const { showError, showSuccess } = useNotificationStore()
const { mutate, loading, onDone, onError } = useUpdateUserMutation()

const { values, meta } = useForm({
  validationSchema: toTypedSchema(companyDetailsSchema),
  initialValues: {
    jobLevel: props.me.jobLevel ?? undefined,
    companyDepartment: props.me.companyDepartment ?? undefined,
    companySize: props.me.companySize ?? undefined,
    companyName: props.me.companyName ?? undefined,
    companyEmail: props.me.companyEmail ?? undefined,
    companyHomepage: props.me.companyHomepage ?? undefined,
    companyPhone: props.me.companyPhone ?? undefined,
    companyIndustryId: props.me.companyIndustryId ?? undefined,
    companyFieldId: props.me.companyFieldId ?? undefined,
    companyStreet: props.me.companyStreet ?? undefined,
    companyNumber: props.me.companyNumber ?? undefined,
    companyAdditionalAddress: props.me.companyAdditionalAddress ?? undefined,
    companyZip: props.me.companyZip ?? undefined,
    companyCity: props.me.companyCity ?? undefined,
    companyCountry: props.me.companyCountry ?? undefined,
  },
})

const companySizeOptions = computed(() =>
  props.userPropertiesPreferences.companySizes.map((cs) => {
    return { value: cs.enum, text: cs.name }
  }),
)
const companyDepartmentOptions = computed(() =>
  props.userPropertiesPreferences.companyDepartments.map((cd) => {
    return { value: cd.enum, text: cd.name }
  }),
)
const companyIndustryOptions = computed(() =>
  props.userPropertiesPreferences.companyIndustries.map((ci) => {
    return { value: ci.id.toString(), text: ci.translation }
  }),
)
const companyFieldOptions = computed(() =>
  props.userPropertiesPreferences.companyFields
    .filter((cf) => cf.companyIndustryId.toString() == values.companyIndustryId)
    .map((cf) => {
      return { value: cf.id.toString(), text: cf.translation }
    }),
)
const jobLevelOptions = computed(() =>
  props.userPropertiesPreferences.jobLevels.map((jl) => {
    return { value: jl.enum, text: jl.name }
  }),
)

const countryOptions = computed(() =>
  props.userPropertiesPreferences.countries
    .map((item) => ({ text: item.translation, value: item.code }))
    .sort((a, b) => a.text.localeCompare(b.text)),
)

const saveChanges = () => {
  if (loading.value) return

  mutate({ userId: props.me.id, attributes: values })
}

onDone((result) => {
  if (result?.data?.updateUser?.errors?.length) {
    showError(result.data.updateUser.errors[0])
  } else {
    showSuccess(t('messages.saved'))
  }
})

onError(() => {
  showError(t('errors.generic'))
})
</script>
