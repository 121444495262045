<template>
  <div v-if="dashboardTicketsLoading || userPreferencesLoading" class="mt-5 w-full text-center">
    <CircleSpinner size="large" />
  </div>

  <template v-else>
    <div v-if="filteredTickets?.length && userPropertiesResult?.userPropertiesPreferences" class="w-full bg-grey-100 py-12">
      <div class="responsive-container mx-auto max-w-4xl">
        <h2 class="text-headline-l-uppercase">{{ $t('tickets.headline') }}</h2>

        <div class="mt-6 flex flex-col gap-16">
          <TicketDetail
            v-for="(ticket, index) in filteredTickets"
            :key="ticket.id"
            :ticket="ticket"
            :current-event-slug="props.currentEventSlug"
            :user-properties="userPropertiesResult.userPropertiesPreferences"
            :number="index + 1"
          />
        </div>
      </div>
    </div>

    <div v-else-if="filteredTickets" class="w-full bg-grey-100 pb-8 pt-2">
      <div class="responsive-container mx-auto my-16 max-w-2xl">
        <div class="mb-8 flex w-full items-center gap-4 rounded-md bg-white p-4">
          <img src="@/assets/empty-state-graphic.svg" class="w-12" />
          <h5 class="text-body-l">{{ $t('tickets.no_tickets.headline') }}</h5>
        </div>
        <h3 class="text-headline-xs">{{ $t('tickets.no_tickets.title_2') }}</h3>
        <p class="text-body-m mb-4">{{ $t('tickets.no_tickets.bullet_2') }}</p>
        <h3 class="text-headline-xs mt-8">{{ t('tickets.no_tickets.title_1') }}</h3>
        <p class="text-body-m mb-4">{{ $t('tickets.no_tickets.bullet_1') }}</p>
        <a href="https://tickets.omr.com" target="_blank" class="btn-primary-yellow-m">{{ $t('tickets.no_tickets.cta') }}</a>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CircleSpinner } from '@ramp106/omrjs-core-ui'
import { useDashboardTicketsQuery, useUserPropertiesPreferencesQuery } from '@/gql/requests.generated'
import TicketDetail from '@/components/TicketDetail/TicketDetail.vue'
import { useNotificationStore } from '@/stores/notification'

const { showError } = useNotificationStore()

const { result: userPropertiesResult, loading: userPreferencesLoading, onError: onErrorUserProps } = useUserPropertiesPreferencesQuery()
const { result: dashboardTicketsResult, loading: dashboardTicketsLoading, onError: onErrorTickets } = useDashboardTicketsQuery()

const { t } = useI18n()

const props = defineProps<{
  ticketId?: string
  currentEventSlug?: string
}>()

const filteredTickets = computed(() => {
  if (!dashboardTicketsResult.value?.dashboardTickets) return null

  if (props.ticketId) return dashboardTicketsResult.value.dashboardTickets.filter((ticket) => ticket.id === props.ticketId)

  return dashboardTicketsResult.value.dashboardTickets
})

onErrorTickets(() => {
  showError(t('tickets.errors.loading_tickets'))
})

onErrorUserProps(() => {
  showError(t('tickets.errors.loading_user_preferences'))
})
</script>
