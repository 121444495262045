<template>
  <div class="inline-flex flex-col items-center gap-7">
    <img class="h-40 w-40 rounded-full object-cover" :src="profileImageSrc" />

    <input id="profile_image" ref="profileImageInput" class="hidden" type="file" @change="handleImageChanged" />

    <label v-if="!newProfileImage" class="btn-secondary-purple-m" for="profile_image">{{ $t('my_account.details.upload_image') }}</label>
    <div v-else class="flex items-center gap-2">
      <button class="btn-primary-purple-m" @click="uploadProfileImage">{{ $t('my_account.details.upload_image_save') }}</button>
      <button class="btn-secondary-purple-m" @click="resetProfileImageChanges">{{ $t('my_account.details.upload_image_cancel') }}</button>
    </div>
  </div>

  <div class="mt-20 w-full tablet:max-w-sm">
    <SelectForOptions name="salutation" :label="$t('my_account.details.salutation')" :options="salutationOptions" />
    <TextInput name="firstName" :label="$t('my_account.details.first_name')" />
    <TextInput name="lastName" :label="$t('my_account.details.last_name')" />
    <div v-if="me.unconfirmedEmail" class="text-body-s-bold mb-2">
      <i18n-t keypath="my_account.email_change_notice" tag="span" scope="global">
        <template #unconfirmed_email>
          {{ me.unconfirmedEmail }}
        </template>
        <template #action>
          <button class="btn-text-mint-m" @click="resendConfirmation()">{{ $t('my_account.resend_instructions_cta') }}</button>
        </template>
      </i18n-t>
    </div>
    <TextInput name="email" :label="$t('my_account.details.email')" />
    <DateInput name="birthdate" :label="$t('my_account.details.birthdate')" />
    <TextInput name="phone" :label="$t('my_account.details.phone')" />
    <CheckBox size="medium" name="networkingAccepted">{{ $t('my_account.details.networking_accepted') }}</CheckBox>
    <CheckBox size="medium" name="omrMatchConsent">{{ $t('my_account.details.omr_match_consent') }}</CheckBox>
  </div>

  <button class="btn-primary-purple-m mt-6 gap-2" :disabled="!meta.valid" type="button" @click="saveChanges">
    {{ $t('ticket.form.save') }}
    <CircleSpinner v-if="loading" size="small" />
  </button>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { SelectForOptions, TextInput, DateInput, CheckBox } from '@ramp106/omrjs-vee-validate'
import { CircleSpinner } from '@ramp106/omrjs-core-ui'
import { accountDetailsSchema } from './accountDetailsSchema'
import { MeType, UserPropertiesPreferences } from '@/gql/types'
import { useResendConfirmationInstructionsMutation, useUpdateUserMutation } from '@/gql/requests.generated'
import { useNotificationStore } from '@/stores/notification'

const { t } = useI18n()

const props = defineProps<{
  me: MeType
  userPropertiesPreferences: UserPropertiesPreferences
}>()
const { showError, showSuccess } = useNotificationStore()
const { mutate: updateUser, loading, onDone: updateUserDone, onError: updateUserError } = useUpdateUserMutation()
const {
  mutate: resendConfirmation,
  onDone: resendConfirmationDone,
  onError: resendConfirmationError,
} = useResendConfirmationInstructionsMutation()

const profileImageInput = ref<HTMLInputElement>()
const newProfileImage = ref<string>()

const { values, meta } = useForm({
  validationSchema: toTypedSchema(accountDetailsSchema),
  initialValues: {
    salutation: props.me.salutation ?? undefined,
    firstName: props.me.firstName ?? undefined,
    lastName: props.me.lastName ?? undefined,
    email: props.me.email ?? undefined,
    birthdate: props.me.birthdate ?? undefined,
    phone: props.me.phone ?? undefined,
    networkingAccepted: props.me.networkingAccepted ?? undefined,
    omrMatchConsent: props.me.omrMatchConsent ?? undefined,
  },
})

const salutationOptions = computed(() =>
  props.userPropertiesPreferences.salutations.map((item) => ({ text: item.name, value: item.enum as string })),
)

const profileImageSrc = computed(() => newProfileImage.value || props.me.profileImage)

updateUserDone((result) => {
  if (result?.data?.updateUser?.errors?.length) {
    showError(result.data.updateUser.errors[0])
  } else {
    showSuccess(t('messages.saved'))
  }
})

resendConfirmationDone((result) => {
  if (result?.data?.resendConfirmationInstructions?.errors?.length) {
    showError(result.data.resendConfirmationInstructions.errors[0])
  } else {
    showSuccess(t('my_account.email_confirmation_instructions'))
  }
})

updateUserError(() => {
  showError(t('errors.generic'))
})

resendConfirmationError(() => {
  showError(t('errors.generic'))
})

function saveChanges() {
  if (loading.value) return

  updateUser({ userId: props.me.id, attributes: values })
}

function handleImageChanged(event: Event) {
  const inputElement = event.target as HTMLInputElement

  if (!inputElement.files || !inputElement.files.length) return

  const reader = new FileReader()
  reader.onload = function () {
    newProfileImage.value = reader.result as string
  }

  reader.readAsDataURL(inputElement.files[0])
}

async function uploadProfileImage() {
  await updateUser({ userId: props.me.id, attributes: { profileImage: profileImageInput.value.files[0] } })
  resetProfileImageChanges()
}

function resetProfileImageChanges() {
  newProfileImage.value = null
  profileImageInput.value.value = ''
}
</script>
