<template>
  <div :class="`mb-3 w-full rounded border px-3 py-3 text-base ${colorClasses}`" role="alert">
    <div class="inline-flex items-start">
      <MdiSvg :path="path" :size="30" class="mr-3 text-warning" />
      <div>
        <h3 v-if="slots.header" class="text-lg font-medium">
          <slot name="header"></slot>
        </h3>
        <div class="text-sm">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'AlertBox' }
</script>

<script setup lang="ts">
import { computed, useSlots } from 'vue'
import { mdiInformation, mdiAlert, mdiCheckCircle, mdiAlphaXCircle } from '@mdi/js'
import { MdiSvg } from '@ramp106/omrjs-core-ui'

const slots = useSlots()

const props = defineProps<{
  type: 'danger' | 'info' | 'success' | 'warning'
  iconPath?: string
}>()

const iconPaths = {
  danger: mdiAlphaXCircle,
  info: mdiInformation,
  success: mdiCheckCircle,
  warning: mdiAlert,
}

const colors = {
  danger: 'bg-error-100 text-error-700',
  info: 'bg-purple-100 text-purple-700',
  success: 'bg-mint-100 text-mint-700',
  warning: 'bg-warning-100 text-warning-900 border-yellow-700',
}

const path = computed(() => props.iconPath || iconPaths[props.type])
const colorClasses = computed(() => colors[props.type])
</script>
